import * as yup from 'yup';
import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Container, FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Input } from '../form';
import { functionaryServices, Head, inscription_services, schoolService, usersServices } from '../../shared'
import { MenuDrawer } from '../Dashboard';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../shared/hooks';
import { toast } from 'react-toastify';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(100%)',
    height: 2,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 2,
});

const schema = yup.object().shape({
    name: yup.string().required().min(2),
    address: yup.string().required(),
    description: yup.string().optional(),
    user_id: yup.number().optional(),
    province_id: yup.number().required().moreThan(0),
    status: yup.number().required(),
    country_id: yup.number().required().moreThan(0),
})

export function AddAndUpdateEsola() {
    const [isUpdate, setIsUpdate] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    const [countries, setCountries] = useState([])
    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            
        }
    })

    const [schools, setsShools] = useState(null);
    const [countrySelected, setCountrySelected] = useState(null);

    const [Loading, setLoading] = useState(false);
    const [data, setData] = React.useState([]);
    const [icons, setIcons] = useState(null);
    const [province, setProvince] = useState([]);
    const [Users, setUsers] = useState([]);

    const { token } = useAuthContext()

    const getIdCountryNacional = () => {
        return countries[countries.findIndex(el => el.name === 'Angola')].id
    }
    
    useEffect(() => {
        if (id && typeof Number(id) === 'number') {
            setIsUpdate(true)
            schoolService.getOneSchool(id).then((data) => {
                setsShools(data.data)
                console.log(data);
            }).catch(err => {
                console.log({ err });
            })
        }

    }, []);

    useEffect(() => {
    
        if (schools) {
           
            handleSelectCountry(6)
            setValue('province_id', schools?.['Provincia angolana']?.id);
            setValue('name', schools.name);
            setValue('address', schools.address);
            setValue('description', schools.description);
            setValue('status', schools.status);
            setValue('user_id', schools.user.id);

        }
    }, [schools]);
    const submitData = async (data) => {
        setLoading(true)
        delete data.country_id;
        console.log(data);
        try {
            const response = await schoolService.POST_school({...data,sort_order: 1, code: '1'});
            
            if(response.error){
                return toast.success('Problemas ao cadastrar Escola');
            }

            toast.success('Escola cadastrada com sucesso')
            navigate('/academic/school')
        } catch (error) {
            console.error(error)
            return toast.success('Problemas ao cadastrar a Escola');
        }finally{
            setLoading(false)
        }
    }


    const submitUpdate = async (data) => {
        setLoading(true)
        delete data.country_id;
        try {
            const response = await schoolService.updateSchool(id, data, token);
            
            if(response.error){
                return toast.success('Problemas ao actualizar a Escola');
            }

            console.log({response});
            if(response.data) {
                toast.success('Escola actualizada com sucesso')
                return navigate('/academic/school')
            }

            return toast.error('Problemas ao actualizar a Escola');
        } catch (error) {
            console.error({error})
            return toast.error('Problemas ao actualizar a Escola');
        }finally{
            setLoading(false)
        }
    }
    
    const getCountries = async () => {
        try {
            const { data } = await inscription_services.getAllCountry();
            setCountries(data)
        } catch (error) {
            console.log(error);
        }
    }

    const getUsers = async () => {
        try {
            const { data } = await usersServices.getAll(token); 

            setUsers(data??[])
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
      getCountries()
      getUsers();
    }, [])

    const handleSelectCountry = async (id) => {
            console.log({id});
            
            setValue('country_id', id??'')
            try {
                const { data } = await inscription_services.getProvinceForCountry(id);
                console.log({ data }, ' \n province');
                setProvince(data)
            } catch (error) {
                console.log(error);

            }
        
    }
    
   


    return (
        <MenuDrawer page='Esolas'>
            <Head title={id ? 'Actualizar dados do Disciplina' : 'Cadastra Escola'} />
            <Box component={Paper} m={2} borderRadius={2}>
                <Box p={1} >
                    <Box component={Container} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        {!id
                            ? (
                                <div style={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Cadastro</h1>
                                    <p>Preencha todos os campos para efectuar o cadastro</p>
                                </div>
                            )
                            : (
                                <div style={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Actualizar</h1>
                                    <p>Edite todos os dados desejado para que a actualização seja bem sucedida</p>
                                </div>
                            )
                        }
                        <Box component={'form'} noValidate encType='multipart/form-data' onSubmit={!!isUpdate ? handleSubmit(submitUpdate) : handleSubmit(submitData)}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label'>Nome</InputLabel>
                                    <Controller
                                        control={control}
                                        name='name'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value}
                                                placeholder={"Digite o nome do funcionário"}
                                                color='primary'
                                                error={!!errors.name}
                                                helperText={errors.name ? errors.name.message : ''}
                                            />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '50%', marginRight: '1rem' }}>

                                    <Controller
                                            control={control}
                                            name='user_id'
                                            rules={{
                                                required: 'Campo obrigatorio'
                                            }}
                                            render={({ field: { value, onChange } }) => (

                                                <>
                                                    <InputLabel className='label'>Director</InputLabel>
                                                    <FormControl fullWidth
                                                        margin="normal">

                                                        <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            fullWidth
                                                            onChange={onChange} value={value ?? ''}
                                                            color='primary'
                                                            error={!!errors.user_id}
                                                            label='Selecione'
                                                        >
                                                            {Users.length > 0 && Users.map((el) => (<MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>))}
                                                        </Select>
                                                    </FormControl>
                                                </>
                                            )}
                                        />
                                    </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    {/* <InputLabel className='label'>Localização</InputLabel>
                                    <Controller
                                        control={control}
                                        name='location'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange} 
                                                value={data.localizacao}
                                                placeholder={'Digite a localização'}
                                                color='primary'
                                                error={!!errors.location}
                                                helperText={errors.location ? errors.location.message : ''}
                                            />
                                        )}
                                    /> */}
                                    <Controller
                                        control={control}
                                        name='country_id'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (

                                            <>
                                                <InputLabel className='label'>País</InputLabel>
                                                <FormControl fullWidth
                                                    margin="normal">

                                                    <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={(e) => handleSelectCountry(e.target.value)} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.years}
                                                        label='Selecione'
                                                    >
                                                        {countries.length > 0 && countries.map((el) => (<MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>))}
                                                    </Select>
                                                </FormControl>
                                            </>
                                        )}
                                    />
                                </div>
                                <div style={{ width: '50%', marginRight: '1rem' }}>

                                <Controller
                                        control={control}
                                        name='province_id'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (

                                            <>
                                                <InputLabel className='label'>Província</InputLabel>
                                                <FormControl fullWidth
                                                    margin="normal">

                                                    <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={onChange} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.years}
                                                        label='Selecione'
                                                    >
                                                        {province.length > 0 && province.map((el) => (<MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>))}
                                                    </Select>
                                                </FormControl>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label'>Endereço</InputLabel>
                                    <Controller
                                        control={control}
                                        name='address'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value ?? ''}
                                                placeholder={'Digite sua localização'}
                                                error={!!errors.address}
                                                helperText={errors.address ? errors.address.message : ''}
                                                color='primary' />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '50%', marginRight: '1rem' }}>

                                <Controller
                                        control={control}
                                        name='status'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (

                                            <>
                                                <InputLabel className='label'>Status</InputLabel>
                                                <FormControl fullWidth
                                                    margin="normal">

                                                    <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={onChange} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.status}
                                                        label='Selecione'
                                                    >
                                                       <MenuItem value={1}>Activo</MenuItem>
                                                       <MenuItem value={2}>Inactivo</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label' sx={{ mt: 1, mb: 2 }}>Logotipo</InputLabel>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        fullWidth
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                        sx={{ mb: 2, py: 2 }}
                                    >
                                        Carregar Imagem
                                        <VisuallyHiddenInput name='icons' type='file'
                                            onChange={(e) => setIcons(e.target.files)} />
                                    </Button>
                                </div>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label'>Descrição</InputLabel>
                                    <Controller
                                        control={control}
                                        name='description'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                
                                                margin="normal"
                                                onChange={onChange}
                                                value={value ?? ''}
                                                placeholder={'Digite sua localização'}
                                                error={!!errors.address}
                                                helperText={errors.address ? errors.address.message : ''}
                                                color='primary' />
                                        )}
                                    />
                                </div>
                                </div>
                            <Box>
                                {id ?
                                    <Box sx={{ display: 'flex', my: 2 }}>
                                        <Button
                                            variant='contained'
                                            sx={{ mb: 2, py: 2 }}
                                            color='primary'
                                            fullWidth
                                            type='submit'
                                            onSubmit={handleSubmit(submitUpdate)}
                                            endIcon={Loading? <CircularProgress size={'1rem'} color='primary' />: undefined}

                                        >
                                            Actualizar
                                        </Button>
                                    </Box>
                                    :
                                    <Box sx={{ my: 2 }}>
                                        <Button
                                            sx={{ mb: 2, py: 2 }}
                                            variant='contained'
                                            color='success'
                                            fullWidth
                                            type='submit'
                                            onSubmit={handleSubmit(submitData)}
                                            disabled={Loading}
                                            endIcon={Loading? <CircularProgress size={'1rem'} color='primary' />: undefined}
                                        >
                                            Guardar
                                        </Button>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MenuDrawer>
    )
}