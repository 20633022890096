import React, { useEffect, useMemo, useState } from 'react'
import { MenuDrawer, PnoWrap } from '../../components'
import { Environment, studentServices, Head, inscription_services, schoolService } from '../../shared'
import {
    Box, Button, Divider, FormControl, Grid, Icon, IconButton, InputBase, InputLabel, LinearProgress, MenuItem, Pagination,
    Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    TextField,
    Typography, useTheme
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { useSearchParams, Link } from 'react-router-dom';
import { useAuthContext } from '../../shared/hooks';
import { toast } from 'react-toastify';

export function StudentEnrolled() {
    const { palette } = useTheme();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [school, setSchool] = React.useState([]);
    const [SearchParmams, setSearchParmams] = useSearchParams({ page: 1 });
    const [note, setNote] = useState([])

    const { token } = useAuthContext()
    const [totalAccount, setTotalAccount] = React.useState(0);
    const [Change, setChange] = React.useState(false);

    const search = useMemo(() => {
      return SearchParmams?.get('search') || ''
    }, [SearchParmams])
  
    const page = useMemo(() => {
      return SearchParmams?.get('page') || 1
    }, [SearchParmams])

    const deletestudent = async (id) => {
        try {
            await studentServices.delOne(id);
            alert('Alunos eliminado com sucesso')
        } catch (error) {
            console.log(error);
        }
    }

    const findAllSchools = async () => {
        try {
            const {url, options} = schoolService.GET_school(undefined, 1000000000, '', token ?? '')
            const response = await fetch(url, options)
            const result = await response.json();
            console.log({result});
            
            setSchool(result.data);
        } catch (error) {
            console.log({error});
        } 
    }

    useEffect(() => {
      findAllSchools()
    }, [])
    

    React.useEffect(() => {
        const findAllCandidate = async () => {
          try {
            setLoading(true);
            const response = await inscription_services.getAllCandidate(page, Environment.ROW_LIMIT, search ?? '', token ?? '');
            console.log({response});
            
            setData(response?.data??[]);
            setTotalAccount(response?.meta.total)
          } catch (error) {
            if (error.message === 'Failed to fetch')
              toast.error('Problemas de conexão com a internet!')
            toast.error(error.message)
    
          } finally {
            setLoading(false);
          }
        }
        findAllCandidate();
        setChange(false)
      }, [search, page, Change]);

      
      const setNoteStudent = async (average, user_id) => {
        try {
            const result = await inscription_services.setNote({average, user_id}, token)
            
            console.log(result);
            
            if(result.data) {
                toast.success('Nota atribuida com sucesso')

                setChange(true)
                return;
            }
            return toast.error('Problemas ao atribuir a nota!');
        } catch (error) {
            console.error({error})
            return toast.error('Problemas ao atribuir a nota!');  
        }
      }

      const manualSelection = async (user_id) => {
        const userSelected = data[data.findIndex(el => el.id === user_id)]
        console.log({userSelected});
        if(!userSelected.media_exam_access?.average) return toast.error('Problemas ao selecionar. Aluno sem nota Atribuida')
        
        try {
            const result = await inscription_services.manualSelect(user_id, token);

            console.log(result);
            
            if(result[0] === 'Candidatos selecionados com sucesso') {
                toast.success(userSelected.name + ' selecionado com sucesso!!!')

                setChange(true)
                return;
            }
            return toast.error('Problemas ao Selecionar o aluno!');

        } catch (error) {
            console.log(error);
            return toast.error('Problemas ao Selecionar o aluno!');            
        }
      }

      const automaticSelection = async (user_id) => {
       
        try {
            const result = await inscription_services.automaticSelect(token);

            console.log(result);
            if(result.original) {

            return toast.error(result.original.message);
            }


            if(result[0] === 'Candidatos selecionados com sucesso') {
                toast.success('Seleção automatica realizada com sucesso!!!')

                setChange(true)
                return;
            }

            return toast.error('Problemas ao Selecionar os alunos!');

        } catch (error) {
            console.log(error);
            return toast.error('Problemas ao Selecionar os alunos!');            
        }
      }

    return (
        <MenuDrawer page='Candidatos Inscritos'>
            <Head title='Candidatos Inscritos' />
            <Box component={Paper} py={2} m={2} flex={1}>
                <Box m={1} ml={2}>
                    <Box m={1} >
                        <Grid container sx={{ display: 'flex', alignItems: 'center', py: 2 }} spacing={1} gap={1} >
                            <Grid item>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 250, border: .1, borderColor: 'gray' }}
                                >
                                    <InputBase
                                        size='small'
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Pesquisar"
                                        onChange={(evt) => setSearchParmams({search: evt.target.value}, { replace: false})}
                                        inputProps={{ 'aria-label': 'search google maps' }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </Grid>
                            {/* <Grid item>
                                <Box >
                                    <FormControl sx={{ minWidth: 250, maxWidth: 250 }} size="small">
                                        <InputLabel id="demo-simple-select-label" sx={{ display: 'flex', gap: 1 }}>Relatório <Icon>print</Icon></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            size='small'
                                            id="demo-simple-select"
                                            value={''}
                                            label="Relatório"
                                            sx={{ py: .5 }}
                                        >
                                            <MenuItem value={10}>Janeiro</MenuItem>
                                            <MenuItem value={20}>Fevereiro</MenuItem>
                                            <MenuItem value={30}>Março</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid> */}
                            <Grid item>
                                <Button variant='contained' onClick={() => automaticSelection()} className='btn-success'>
                                    Seleção Automatica <i className='fas fa-circle-check'></i>
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Box>
                    <Box marginBottom={5}>
                        <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
                            <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }} >
                                <Table sx={{ minWidth: 650, color: 'white' }} size='small' aria-label="a dense table">
                                    <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: 'white' } }}>
                                        <TableRow>
                                            <TableCell className='title-table text-center'><PnoWrap color='white' text='Nº de Processo' /></TableCell>
                                            <TableCell className='title-table text-center'>Nome</TableCell>
                                            <TableCell className='title-table text-center'>Escola</TableCell>
                                            <TableCell className='title-table text-center'>Curso</TableCell>
                                            <TableCell className='title-table text-center'>Sala</TableCell>
                                            <TableCell className='title-table text-center'><PnoWrap color='white' text='Nota do exame'/></TableCell>
                                            <TableCell className='title-table text-center'>Acções</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.length > 0 &&
                                            data.map((item, index) => (
                                                <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell className='text-center'>{item.document.value}</TableCell>
                                                    <TableCell className='text-center'>{item.name}</TableCell>
                                                    <TableCell className='text-center'><PnoWrap color='black' text={school[school.findIndex(el => el.id === item.application.locations.id)].name} /></TableCell>
                                                    <TableCell className='text-center'>{item.application.courses.name}</TableCell>
                                                    <TableCell className='text-center'>{item.turma}</TableCell>
                                                    <TableCell className='text-center'>
                                                        <Box position={'relative'} display={'flex'}  sx={{width: 70}}>
                                                            <TextField 
                                                                size='small' 
                                                                disabled={!!item.media_exam_access?.average}
                                                                value={item.media_exam_access?.average ? item.media_exam_access?.average : note[index]}
                                                                onChange={(e) => setNote(old => {
                                                                    const newArray = [...old]; 
                                                                    newArray[index] = e.target.value; 
                                                                    return newArray;
                                                                })}
                                                            />
                                                            {!item.media_exam_access && <Box position={'absolute'} right={0} width={30} height={'100%'}>
                                                                <button 
                                                                    style={{
                                                                        height: '100%', 
                                                                        width: '100%', 
                                                                        background: 'blue', 
                                                                        color: 'white',
                                                                        borderRadius: 4,
                                                                        border: 'none', 
                                                                        cursor: 'pointer',
                                                                        
                                                                    }} className=''
                                                                    onClick={(e) => setNoteStudent(note[index], item.id) }    
                                                                >
                                                                    <i className='fa fa-check-circle'></i>
                                                                </button>
                                                            </Box>}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Box display={'flex'} gap={1} justifyContent={'center'}>
                                                            <Link className='btn-icon' to={`/people/students/show/${item.id}`}>
                                                                <i className="fa-solid fa-eye"></i>
                                                            </Link>
                                                            <button className='btn-icon' onClick={() => manualSelection(item.id)} title={`Selecionar o(a) candidato(a) ${item.name}`}>
                                                                <i className="fas fa-check"></i>
                                                            </button>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }

                                        {loading && (
                                            <TableRow>
                                                <TableCell colSpan={10} align="center"><LinearProgress color='secondary' /></TableCell>
                                            </TableRow>)
                                        }

                                    </TableBody>
                                    <TableFooter>
                                        {data.length === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={12} align="center"><Typography width={250}>Lista de produtos vazia</Typography></TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                                                <Pagination
                                                    count={Math.ceil(totalAccount / Environment.ROW_LIMIT)}
                                                    page={Number(page)} onChange={(_, newPage) => { setSearchParmams({ _, page: newPage.toString() }, { replace: true }) }}
                                                    color='primary'
                                                    size='small'
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>

                    </Box>
                </Box>

            </Box>

        </MenuDrawer>
    )
}
