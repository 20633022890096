import { createContext, useEffect, useState } from "react";
import { useLocalData } from "../hooks";
import { sessionServices } from "../services/auth";
import { deleteTokenLocalData, deleteUserLocalData } from "../LocalData";
import { useNavigate } from "react-router-dom";

export const SessionContext = createContext({
    user: undefined,
    token: null,
    isLoading: false,
    setIsLoading: (value) => { },
    logout: () => { },
    session: async (email, password) => { },
});

export const SessionContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true)
    const [token, setToken] = useState(null)
    const { getUserLocalData, getTokenLocalData, setTokenLocalData, setUserLocalData,cleanLocalData } = useLocalData()
    const navigate = useNavigate()
    // const [control, setControl] = useState({id: 1, exist: false});

    useEffect(() => {
        const userLocal = getUserLocalData();
        const tokenLocal = getTokenLocalData();
        if (userLocal) {
            setUser(JSON.parse(userLocal));
            setToken(JSON.parse(tokenLocal));
        }
        setIsLoading(false);
    }, []);

    const session = async (email, password) => {
        try {
            setIsLoading(true);
            const result = await sessionServices.login({ email, password })

            if (result.data && result.data.user) {
                const { user, access_token } = result.data;
                setTokenLocalData(access_token)
                setToken(access_token)
                setUserLocalData(user);
                setIsLoading(false)
                return true;
            }

            if(result.error)
                throw new Error(result.error === 'Unauthorized' ? 'Não autorizado, verifique se inseriu informações corretas!': result.error)
        } catch (error) {
            throw new Error(error.message)
        } finally {
            setIsLoading(false)
        }
    }

    const logout = () => {
        setUser(null);
        setToken(null)
        deleteUserLocalData()
        deleteTokenLocalData();
        cleanLocalData()        
        navigate('/');
    }

    return (
        <SessionContext.Provider value={{ user, token, logout, isLoading, setIsLoading, session }}>
            {children}
        </SessionContext.Provider>
    )
}
