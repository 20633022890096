// styles
import styles from './Inscription.module.css'
import styless from '../../components/formComponents/Select.module.css'
// dependencies
import React, { useEffect, useState } from 'react'
import { inscription_services } from '../../shared'
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom'
import { useLocalData } from '../../shared/hooks'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { SendNIf } from './components_2/SendNIf'
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, CircularProgress, FormControl, FormLabel, Grid, Icon, InputLabel, MenuItem, Typography } from '@mui/material'
import { PnoWrap } from '../../components'
// components
import Header from '../../components/Header'
import Input from '../../components/formComponents/Input'
import Select from '../../components/formComponents/Select'

const schema = yup.object().shape({
    full_name: yup.string().required('Nome Completo obrigatorio').min(6, 'Nome Completo deve ter no minimo 6 caracteres'),
    nif: yup.string().required('numero do documento de identidade obrigatório'),
    birth_date: yup.string().required('Informe sua data de nascimento'),
    gender: yup.string().oneOf(['M', 'F']).required('Genêro obrigatório'),
    country_id: yup.number().required('País é obrigatorio'),
    province_id: yup.number().required('Província é obrigatorio'),
    natural_from: yup.string().optional(),
    debilities: yup.number().optional(),
    whereNoneClass: yup.boolean().optional(),
    last_school_type_id: yup.number().optional(),
    last_school_name: yup.string().required('Nome da escola onde frequentou a 9º classe obrigatorio').min(3, 'minimo de caracteres 3'),
    final_score: yup.number().required('Informe a sua média ou nota final'),
    last_school_country_id: yup.number().required('Informe o país em que frequêntou.'),
    last_school_province_id: yup.number().required('Informe a província ou cidade em que frequêntou.'),
    last_school_end_year: yup.number().required('Informe o ano em que concluíu a 9º classe'),
    last_school_city: yup.string().required('Em que minícipio situava a sua anterior escola'),
    debilities_description: yup.string().optional(),
    // location: yup.string().required('Campo obrigatorio').min(4, 'Localição deve ter no minimo 4 caracteres'),
})


const def = ['Visual',
'Auditivo',
'Fisico Motora' ,
'Intelectual',
'Surdo/ Cegueira',
'Hiperativo',
'Multiplas',
'TDG Autista',
'Carência Afectiva' ,
'Altas Habilidades' ,
'Transtornos N. Especificados',
'D. Aprendizagem',
'Sindrome-Dawn',
'Transtorno da Fala',
    'Outros']



const Inscription = () => {
    const generos = [
        {
            id: 0,
            name: 'Masculino',
            value: 'masculino'
        },
        {
            id: 1,
            name: 'Femenino',
            value: 'femenino'
        },
    ];

    const paises = [
        {
            id: 0,
            name: 'Angola',
            value: 'angola'
        },
        {
            id: 1,
            name: 'Moçambique',
            value: 'mocambique'
        },
        {
            id: 2,
            name: 'Portugal',
            value: 'portugal'
        },
    ]

    const { control, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            full_name: "",
            last_school_name: '',
            birth_date: "",
            gender: "",
            country_id: undefined,
            province_id: undefined,
            nif: '',
            natural_from: '',
            final_score: undefined,
            last_school_city: '',
            debilities: 1,
            whereNoneClass: false,
            last_school_end_year: undefined,
        }
    });

    const seeDebilite = watch('debilities')
    const params = useParams();
    
    const { setCandidateLocalData } = useLocalData();
    const navigate = useNavigate()

    const [user, setUser] = useState(null);
    const [BI, setBI] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [OpenModalNif, setOpenModalNif] = useState(true);
    const [countries, setCountries] = useState([]);
    const [province, setProvince] = useState([]);
    const [province2, setProvince2] = useState([]);
    const [last_school_type_id, setlast_school_type_id] = useState([]);

    useEffect(() => {
      window.addEventListener('popstate', (evt) => {
        // alert('Maaaaaaaaaaaaaa', evt);
        navigate('/inscription')
        console.log(evt);
      })

    }, [])
    


    const handleSetNif = async () => {
        setLoading(true)

        setValue('nif', BI ?? '');
        try {
            const result = await fetch(`https://consulta.edgarsingui.ao/consultar/${BI}/bilhete`);
            const response = await result.json()
            console.log({ response });

            if (!response?.error) {
                setUser(response);
            }
        } catch (error) {
            console.log('err=> ', error);

        } finally {
            setOpenModalNif(false)
            setLoading(false)
        }
    }

    const submit = (data) => {
        setLoading(true);
        setCandidateLocalData(data);
        setLoading(false)
        navigate('/inscription/two')
    }

    useEffect(() => {
        if (user) {
            setValue('full_name', user.name);
            setValue('birth_date', user.data_de_nascimento); 
            setValue('nif', BI ?? '');
            setValue('gender', user.gender);
            setValue('country_id', user.country_id);
            if (params.id && Number(params.id) === 1) {
                setValue('country_id', getIdCountryNacional())
                handleSelectCountry(getValues('country_id'))
            }

            setValue('last_school_country_id', getIdCountryNacional())
            handleSelectCountry(getValues('last_school_country_id'), 2)
        }
    }, [user]);
    

    const getCountries = async () => {
        try {
            const { data } = await inscription_services.getAllCountry();
            console.log({ data }, ' \ncountries');
            setCountries(data)
        } catch (error) {
            console.log(error);
        }
    }

    const getlast_school_type_id = async () => {
        try {
            const { data } = await inscription_services.getSchoolType();
            console.log({ data }, ' \nlast_school_type_id');
            setlast_school_type_id(data)
        } catch (error) {
            console.log(error);
        }
    }

    const handleSelectCountry = async (id, control=1) => {
        if(control === 1){
            console.log({id});
            
            setValue('country_id', id??'')
            try {
                const { data } = await inscription_services.getProvinceForCountry(id);
                console.log({ data }, ' \n province');
                setProvince(data)
            } catch (error) {
                console.log(error);

            }
        } else {
            setValue('last_school_country_id', id ?? '')
            try {
                const { data } = await inscription_services.getProvinceForCountry(id);
                console.log({ data }, ' \n province');
                setProvince2(data)
            } catch (error) {
                console.log(error);

            }
        }
    }

    useEffect(() => {
        getCountries()
        getlast_school_type_id()
    }, [])

    const getIdCountryNacional = () => {
        return countries[countries.findIndex(el => el.name === 'Angola')].id
    }

    return (
        <section style={{ background: '#fff' }}>
            <Header />
            {OpenModalNif ? <SendNIf nif={BI} loading={Loading} setClose={handleSetNif} onChangeNif={setBI} /> :

                <section className={styles.inscription}>
                    <h1 className='title'>Ficha de inscrição</h1>
                    <p>Dados-Pessoais | Preencha todos os campos</p>
                    <div className={styles.blockForm}>
                        <Box component={'form'} noValidate encType='multipart/form-data' onSubmit={handleSubmit(submit)}>
                            <Grid container spacing={2}>
                                <Grid item container spacing={2} xs={12} sm={12} md={12}>
                                    <Grid item md={8}>
                                        <Controller
                                            control={control}
                                            name='full_name'
                                            render={({ field: { value, onChange } }) => (
                                                <FormControl fullWidth>
                                                    <Input fullWidth
                                                        label='Nome Completo'
                                                        placeholder='Digite seu nome'
                                                        error={!!errors.full_name?.message}
                                                        errorText={errors.full_name?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange}
                                                    />
                                                    {errors.full_name?.message && <Typography color={'red'} fontSize={12}>{errors.full_name?.message}</Typography>}
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <Controller
                                            control={control}
                                            name='birth_date'
                                            render={({ field: { value, onChange } }) => (
                                                <FormControl fullWidth>
                                                    <Input
                                                        fullWidth
                                                        label='Data de Nascimento'
                                                        type={'date'}
                                                        placeholder='Digite seu nº de B.I ou Passaporte'
                                                        error={!!errors.birth_date?.message}
                                                        errorText={errors.birth_date?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange}
                                                    />
                                                    {errors.birth_date?.message && <Typography color={'red'} fontSize={12}>{errors.birth_date?.message}</Typography>}
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item container spacing={2} xs={12} sm={12} md={12}>
                                        <Grid item md={3}>
                                            <Controller
                                                control={control}
                                                name='gender'
                                                render={({ field: { value, onChange } }) => (
                                                    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                                        <FormControl fullWidth>
                                                            <div className={styless.wrapper}>
                                                                <label htmlFor={'gender'}>Selecione seu gênero</label>
                                                                <select id={'gender'} name={'gender'} onChange={onChange} value={value}>
                                                                    <option>Selecione uma opção</option>
                                                                        <option value={'M'}>Masculino</option>
                                                                        <option value={'F'}>Femenino</option>
                                                                </select>
                                                            </div>
                                                            {errors.gender?.message && <Typography color={'red'} fontSize={12}>{errors.gender?.message}</Typography>}
                                                        </FormControl></Box>
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={3} >
                                            <Box>
                                                <Controller
                                                    control={control}
                                                    name='country_id'
                                                    render={({ field: { value } }) => (
                                                        <Box display={'flex'} flexDirection={'column'} >
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={value ?? ''}
                                                                    label="Selecione o País"
                                                                    onChange={(evt) => handleSelectCountry(evt.target.value)}
                                                                    fullWidth
                                                                    values={countries}
                                                                ></Select>
                                                                {errors.country_id?.message && <Typography color={'red'} fontSize={12}>{errors.country_id?.message}</Typography>}

                                                            </FormControl></Box>
                                                    )}
                                                />
                                            </Box>
                                        </Grid>

                                    <Grid item xs={12} sm={12} md={3} >
                                        <Box style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name='province_id'
                                                render={({ field: { value, onChange } }) => (
                                                    <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={value ?? ''}
                                                            label="Selecione a Província"
                                                            onChange={onChange}
                                                            fullWidt
                                                            values={province}
                                                        />
                                                        {errors.province_id?.message &&
                                                            <Typography color={'red'} fontSize={12}>
                                                                {errors.province_id?.message}
                                                            </Typography>
                                                        }
                                                    </Box>
                                                )}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={3} >
                                        <Box style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name='natural_from'
                                                render={({ field: { value, onChange } }) => (
                                                    <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                        <FormControl fullWidth>
                                                            <Input fullWidth
                                                                label={'Naturalidade'}
                                                                type={'text'}
                                                                placeholder='Digite a sua naturalidade'
                                                                error={!!errors.natural_from?.message}
                                                                errorText={errors.natural_from?.message}
                                                                value={value ?? ''}
                                                                onChange={onChange}
                                                            />
                                                            {errors.natural_from?.message && <Typography color={'red'} fontSize={12}>{errors.natural_from?.message}</Typography>}
                                                        </FormControl>
                                                    </Box>
                                                )}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} >
                                        <Box style={{ width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name='debilities'
                                                render={({ field: { value, onChange } }) => (
                                                    <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                        <FormControl fullWidth>
                                                            <div className={styless.wrapper}>
                                                                <label htmlFor={'gender'}>Port. Deficiência</label>
                                                                <select id={'gender'} name={'gender'} onChange={onChange} value={value}>
                                                                    <option>Selecione uma opção</option>
                                                                    <option value={2}>Sim</option>
                                                                    <option value={1}>Não</option>
                                                                </select>
                                                            </div>
                                                            {errors.debilities?.message &&
                                                                <Typography color={'red'} fontSize={12}>
                                                                    {errors.debilities?.message}
                                                                </Typography>
                                                            }
                                                        </FormControl>
                                                    </Box>
                                                )}
                                            />
                                        </Box>
                                    </Grid>
                                    {seeDebilite === '2' && <Grid item xs={12} sm={12} md={9}>
                                        <Controller
                                            control={control}
                                            name='debilities_description'
                                            render={({ field: { value, onChange } }) => (
                                                <FormControl fullWidth>
                                                    <Select fullWidth
                                                        label='Sobre a Deficiência'
                                                        placeholder='Descreva sua deficiência'
                                                        error={!!errors.debilities_description?.message}
                                                        errorText={errors.debilities_description?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange}
                                                        values={def.map((el, id) => ({id, name: el}))}
                                                    />
                                                    {errors.debilities_description?.message && <Typography color={'red'} fontSize={12}>{errors.debilities_description?.message}</Typography>}
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>}
                                </Grid>
                            </Grid>

                            <Box mt={2} mb={1}>
                                <PnoWrap color='grayText' fontWeight={'bold'} fontSize={14} text={'Informações sobre a escola onde frequentou a 9ºclasse'} />
                            </Box>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={3} >
                                    <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='whereNoneClass'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                    <FormControl fullWidth>
                                                        <div className={styless.wrapper}>
                                                                <label htmlFor={'gender'}><PnoWrap color='grayText' text="Onde frequêntou a 9º class" /></label>
                                                                <select id={'gender'} name={'gender'}>
                                                                    <option>Selecione uma opção</option>
                                                                <option value={false}>Nacional</option>
                                                                <option value={true}>Exterior</option>
                                                                </select>
                                                            </div>
                                                            {errors.whereNoneClass?.message && <Typography color={'red'} fontSize={12}>{errors.whereNoneClass?.message}</Typography>}

                                                        </FormControl></Box>
                                                )}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} >
                                            <Controller
                                                control={control}
                                                name='last_school_type_id'
                                                render={({ field: { value, onChange } }) => (
                                                    <Box display={'flex'} flexDirection={'column'}  width={'100%'}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={value ?? ''}
                                                                label="Tipo de escola"
                                                                defaultValue='Publica'
                                                                onChange={onChange}
                                                                fullWidth
                                                                values={last_school_type_id}
                                                            />
                                                            {errors.last_school_type_id?.message && <Typography color={'red'} fontSize={12}>{errors.last_school_type_id?.message}</Typography>}

                                                    </FormControl></Box>
                                            )}
                                        />
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} >
                                    <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='last_school_name'
                                            render={({ field: { value, onChange } }) => (
                                                <FormControl fullWidth style={{ width: '100%' }}>
                                                    <Input fullWidth
                                                        placeholder='Digite o nome da escola'
                                                        label={'Nome da escola'}
                                                        error={!!errors.last_school_name?.message}
                                                        errorText={errors.last_school_name?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange}
                                                    />
                                                    {errors.last_school_name?.message && <Typography color={'red'} fontSize={12}>{errors.last_school_name?.message}</Typography>}

                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} >
                                    <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='final_score'
                                            render={({ field: { value, onChange } }) => (
                                                <FormControl fullWidth style={{ width: '100%' }}>
                                                    <Input fullWidth
                                                        placeholder='Digite o nota final'
                                                        label={'Média final'}
                                                        error={!!errors.final_score?.message}
                                                        errorText={errors.final_score?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange}
                                                        type="number"
                                                    />
                                                    {errors.final_score?.message && <Typography color={'red'} fontSize={12}>{errors.final_score?.message}</Typography>}

                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                </Grid>


                                    <Grid item xs={12} sm={12} md={3} >
                                        <Box>
                                            <Controller
                                                control={control}
                                                name='last_school_country_id'
                                                render={({ field: { value, onChange } }) => (
                                                    <Box display={'flex'} flexDirection={'column'} >
                                                        <FormControl fullWidth>
                                                             <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={value ?? ''}
                                                                label="País"
                                                                onChange={(evt) => handleSelectCountry(evt.target.value, 2)}
                                                                fullWidth
                                                                values={countries}
                                                            >
                                                            </Select>
                                                            {errors.last_school_country_id?.message && <Typography color={'red'} fontSize={12}>{errors.last_school_country_id?.message}</Typography>}

                                                    </FormControl></Box>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} >
                                    <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='last_school_province_id'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={value ?? ''}
                                                            label="Província"
                                                            onChange={onChange}
                                                            values={province2}
                                                            fullWidth
                                                        ></Select>
                                                        {errors.last_school_province_id?.message &&
                                                            <Typography color={'red'} fontSize={12}>
                                                                {errors.last_school_province_id?.message}
                                                            </Typography>
                                                        }
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} >
                                    <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='last_school_city'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                    <FormControl fullWidth>
                                                        <Input fullWidth
                                                            type={'text'}
                                                            label={'Munícipio'}
                                                            placeholder='Digite onde situava'
                                                            error={!!errors.last_school_city?.message}
                                                            errorText={errors.last_school_city?.message}
                                                            value={value ?? ''}
                                                            onChange={onChange}
                                                        />
                                                        {errors.last_school_city?.message && <Typography color={'red'} fontSize={12}>{errors.last_school_city?.message}</Typography>}

                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} >
                                    <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='last_school_end_year'
                                            render={({ field: { value, onChange } }) => (
                                                <FormControl fullWidth style={{ width: '100%' }}>
                                                    <Input fullWidth
                                                        label={'Ano de conclusão'}
                                                        placeholder='Digite o ano conclusão'
                                                        error={!!errors.last_school_end_year?.message}
                                                        errorText={errors.last_school_end_year?.message}
                                                        value={value ?? ''}
                                                        type="number"
                                                        onChange={onChange}
                                                    />
                                                    {errors.last_school_end_year?.message && <Typography color={'red'} fontSize={12}>{errors.last_school_end_year?.message}</Typography>}

                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} mb={2} >
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        fullWidth
                                        type='submit'
                                        disabled={Loading}
                                        onSubmit={handleSubmit(submit)}
                                        endIcon={
                                            Loading ?
                                                <CircularProgress size={'1rem'} /> :
                                                <Icon>save</Icon>
                                        }
                                        sx={{ fontSize: 16 }}
                                    >
                                        Continuar
                                    </Button>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Box>
                    </div>
                </section>}
        </section>
    )
}

export default Inscription
