import { Link } from 'react-router-dom';
import styles from './Modal.module.css';
import React from 'react';

const Modal = ({ setModal }) => {
    return (
        <section className={styles.modal}>
            <div className={styles.container}>
                <div className={styles.hedaer}>
                    <h1 className='title'>Selecione uma opção</h1>
                    <button className={styles.close} title='Fechar' onClick={() => setModal(false)}>
                        X
                    </button>
                </div>
                <p>Selecione uma opção de acordo a sua realidade para fazer inscrição</p>
                <div className={styles.body}>
                    <Link to='/inscription?doc=bi' className={styles.nacional}>Nacional</Link>
                    <Link to='/inscription?doc=ps' className={styles.estrangeiro}>Estrangeiro</Link>
                </div>
            </div>
        </section>
    )
}

export default Modal
