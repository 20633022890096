export const RoutesList = [
    {
        title: 'Pagina Inicial',
        to: '/',
        mainIcon: 'fas fa-house',
        subMenu: undefined
    },
    {
        title: 'Gestão de Usuários',
        to: undefined,
        mainIcon: 'fas fa-users-cog',
        subMenu: [
            {
                title: 'Alunos',
                Icon: 'fas fa-user-graduate',
                to: '/people/students'
            },
            {
                title: 'Cand. Inscritos',
                Icon: 'fas fa-users',
                to: '/people/students/enrolled'
            },
            {
                title: 'Cand. Selecionados',
                Icon: 'fas fa-users',
                to: '/people/students/selected'
            },
            {
                title: 'Funcionários',
                Icon: 'fas fa-user-tie',
                to: '/people/functionary'
            },
            // {
            //     title: 'Resultado',
            //     Icon: 'fas fa-user-tie',
            //     to: '/people/response'
            // },
            {
                title: 'Perfis de Acesso',
                Icon: 'fas fa-user-gear',
                to: '/people/access'
            }
        ]
    },
    {
        title: 'Gestão Escolar',
        to: undefined,
        mainIcon: 'fas fa-school',
        subMenu: [
            {
                title: 'Escolas',
                Icon: 'fas fa-school',
                to: '/academic/school'
            },
            {
                title: 'Cursos',
                Icon: 'fa-solid fa-person-chalkboard',
                to: '/academic/courses'
            },
            {
                title: 'Salas e Turmas',
                Icon: 'fas fa-chalkboard-teacher',
                to: '/academic/classes'
            },
            {
                title: 'Disciplinas',
                Icon: 'fas fa-book',
                to: '/academic/discipline'
            },
            {
                title: 'Grade Curricular',
                Icon: 'fa-solid fa-book-open',
                to: '/academic/courses'
            },
            {
                title: 'Localização Interna',
                Icon: 'fa-solid fa-map',
                to: '/academic/courses'
            },
            {
                title: 'Matrícula',
                Icon: 'fa-solid fa-pen',
                to: '/academic/courses'
            },
            {
                title: 'Confirmação de Matrícula',
                Icon: 'fa-solid fa-edit',
                to: '/academic/performance'
            },
        ]
    },
    // {
    //     title: 'Gestão Académica',
    //     to: undefined,
    //     mainIcon: 'fas fa-school',
    //     subMenu: [
    //         {
    //             title: 'Configuração de Exame',
    //             Icon: 'fa-solid fa-person-chalkboard',
    //             to: '/academic/courses'
    //         },
    //         {
    //             title: 'Desempenho dos Alunos',
    //             Icon: 'fa-solid fa-chart-simple',
    //             to: '/academic/performance'
    //         },
    //         {
    //             title: 'Histórico do Aluno',
    //             Icon: 'fa-solid fa-book-open',
    //             to: ''
    //         },
    //         {
    //             title: 'Niveis Academicos',
    //             Icon: 'fa-solid fa-person-chalkboard',
    //             to: '/academic/levels'
    //         },
    //         {
    //             title: 'Pauta',
    //             Icon: 'fas fa-chalkboard-teacher',
    //             to: '/academic/classes'
    //         },
    //         {
    //             title: 'Transferências',
    //             Icon: 'fas fa-exchange-alt',
    //             to: '/academic/classes'
    //         },
    //     ]
    // },
    {
        title: 'Gestão Frequência',
        to: undefined,
        mainIcon: 'fas fa-person-circle-check',
        subMenu: [
            {
                title: 'Presenças',
                Icon: 'fas fa-check',
                to: '/academic/school'
            },
            {
                title: 'Faltas',
                Icon: 'fas fa-remove',
                to: '/academic/school'
            },
            {
                title: 'Justificativo de Faltas',
                Icon: 'fas fa-user-cog',
                to: '/academic/school'
            },
        ]
    },
    {
        title: 'Gestão Pedagógica',
        to: undefined,
        mainIcon: 'fas fa-wallet',
        subMenu: [
            {
                title: 'Cartão',
                Icon: 'fas fa-id-card',
                to: ''
            },
            {
                title: 'Declaração de Frequência',
                Icon: 'fas fa-file-word',
                to: ''
            },
            {
                title: 'Declaração com Nota',
                Icon: 'fas fa-file-excel',
                to: ''
            },
            {
                title: 'Exame Especial',
                Icon: 'fas fa-rectangle-list',
                to: ''
            },
            {
                title: 'Exame Recurso',
                Icon: 'fas fa-rectangle-list',
                to: ''
            },
            {
                title: 'Folha de Prova',
                Icon: 'fas fa-file',
                to: ''
            },
            {
                title: 'Transferência',
                Icon: 'fas fa-exchange-alt',
                to: ''
            },
            {
                title: 'Certificado',
                Icon: 'fas fa-file-code',
                to: ''
            },
            {
                title: 'Uniforme',
                Icon: 'fas fa-shirt',
                to: ''
            },
            {
                title: 'Energia e Água',
                Icon: 'fa-solid fa-building',
                to: ''
            }
        ]
    },
    {
        title: 'Relatórios e Estatísticos',
        to: undefined,
        mainIcon: 'fas fa-chart-bar',
        subMenu: [
            {
                title: 'Alunos Inscritos',
                Icon: 'fa fa-address-card',
                to: ''
            },
            {
                title: 'Alunos selecionados',
                Icon: 'fa fa-person-chalkboard',
                to: ''
            },
            {
                title: 'Lista de Funcionários',
                Icon: 'fa fa-user-shield',
                to: ''
            },,
            {
                title: 'Água',
                Icon: 'fa fa-address-card',
                to: ''
            },
            {
                title: 'Luz',
                Icon: 'fa fa-person-chalkboard',
                to: ''
            }
        ]
    },
    {
        title: 'Despesas Escolar',
        to: undefined,
        mainIcon: 'fas fa-chart-bar',
        subMenu: [
            {
                title: 'Água',
                Icon: 'fa fa-address-card',
                to: ''
            },
            {
                title: 'Luz',
                Icon: 'fa fa-person-chalkboard',
                to: ''
            }
        ]
    },
    // {
    //     title: 'Comunicação',
    //     to: undefined,
    //     mainIcon: 'fa-solid fa-comment',
    //     subMenu: [
    //         {
    //             title: 'Alunos',
    //             Icon: 'help',
    //             to: ''
    //         },
    //         {
    //             title: 'Coordenação',
    //             Icon: 'help',
    //             to: ''
    //         },
    //         {
    //             title: 'Correio Interno',
    //             Icon: 'fa fa-paper-plane',
    //             to: ''
    //         },
    //         {
    //             title: 'Direcção',
    //             Icon: 'store',
    //             to: ''
    //         },
    //         {
    //             title: 'Instituições de Ensino',
    //             Icon: 'store',
    //             to: ''
    //         },
    //         {
    //             title: 'Notificações',
    //             Icon: 'help',
    //             to: 'fa fa-bell'
    //         },
    //         {
    //             title: 'Professores',
    //             Icon: 'store',
    //             to: ''
    //         },
    //         {
    //             title: 'Pais e Encarregados',
    //             Icon: 'fa fa-world',
    //             to: ''
    //         }
    //     ]
    // },
    // {
    //     title: 'Segurança e Privacidade',
    //     to: undefined,
    //     mainIcon: 'fas fa-lock',
    //     subMenu: [
    //         {
    //             title: 'Abertura e Fecho do Sistema',
    //             Icon: 'key',
    //             to: '/box'
    //         },
    //         {
    //             title: 'Relatório de Acesso',
    //             Icon: 'store',
    //             to: ''
    //         },
    //         {
    //             title: 'Alterar Senha',
    //             Icon: 'assignment',
    //             to: ''
    //         }
    //     ]
    // },
    // {
    //     title: 'Configurações do Sistema',
    //     to: undefined,
    //     mainIcon: 'fa-solid fa-gear',
    //     subMenu: [
    //         {
    //             title: 'Calendário Escolar',
    //             Icon: 'fas fa-calendar',
    //             to: ''
    //         },
    //         {
    //             title: 'Decreto',
    //             Icon: 'fas fa-calendar',
    //             to: ''
    //         },
    //         {
    //             title: 'Horario',
    //             Icon: 'fas fa-clock',
    //             to: ''
    //         },
    //         {
    //             title: 'Regras Interna da Escola',
    //             Icon: 'fas fa-rul',
    //             to: ''
    //         },
    //     ]
    // },
    {
        title: 'Gestão pedagógica',
        to: undefined,
        mainIcon: 'fas fa-folder',
        subMenu: [
            {
                title: 'Mini Pauta',
                Icon: 'fas fa-file',
                to: ''
            },
            {
                title: 'Pauta do 1º Trimestre',
                Icon: 'fas fa-file-word',
                to: ''
            },
            {
                title: 'Pauta do 2º Trimestre',
                Icon: 'fas fa-file-excel',
                to: ''
            },
            {
                title: 'Pauta do 3º Trimestre',
                Icon: 'fas fa-file-powerpoint',
                to: ''
            },
            {
                title: 'Pauta Final',
                Icon: 'fas fa-file-code',
                to: ''
            },
            {
                title: 'Pauta do Exame de Reurso',
                Icon: 'fas fa-file-pdf',
                to: ''
            },
            {
                title: 'Pauta de Exame Especial',
                Icon: 'fas fa-file',
                to: ''
            },
            {
                title: 'Pauta de Melhoria de Nota',
                Icon: 'fas fa-file',
                to: ''
            },
        ]
    },
    {
        title: 'Gestão Administrativa',
        to: undefined,
        mainIcon: 'fas fa-user',
        subMenu: [
            {
                title: 'Declaração de Frequência',
                Icon: 'fas fa-file-word',
                to: ''
            },
            {
                title: 'Declaração com Notas',
                Icon: 'fas fa-file-excel',
                to: ''
            },
            {
                title: 'Transferência',
                Icon: 'fas fa-exchange-alt',
                to: ''
            },
            {
                title: 'Certificado',
                Icon: 'fas fa-file',
                to: ''
            },
            {
                title: 'Termo de Frequência',
                Icon: 'fas fa-file-code',
                to: ''
            },
            {
                title: 'Uniforme Escolar',
                Icon: 'fas fa-user-tie',
                to: ''
            }
        ]
    },
    {
        title: 'Gestão Financeira',
        to: '/',
        mainIcon: 'fas fa-money-bill',
        subMenu: undefined
    },
    {
        title: 'Suporte Técnico',
        to: undefined,
        mainIcon: 'fas fa-headset',
        subMenu: [
            {
                title: 'Suporte Escolar',
                Icon: 'fas fa-phone',
                to: ''
            },
            {
                title: 'Suporte de Sistema',
                Icon: 'fas fa-tools',
                to: ''
            },
        ]
    },
]