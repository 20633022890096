import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import React from 'react'

const Header = () => {
    const [active, setActive] = React.useState(false);

    return (
        <header className={styles.header}>
            {/* desktop */}
            <nav className={styles.desktop}>
                <Link to='/'>INFQE</Link>

                <ul>
                    <li><Link to='/'>Home</Link></li>
                    <li><Link to='/about'>Sobre</Link></li>
                    <li><Link to='/school'>Escolas</Link></li>
                </ul>

                <Link to='/login' className={styles.login}>Entrar</Link>
            </nav>
            {/* mobile */}
            <nav className={styles.mobile}>
                <Link to='/'>INFQE</Link>

                <button className={styles.btnMobile} onClick={() => setActive(!active)}>
                    <i className="fa-solid fa-bars"></i>
                </button>
                <div className={active ? styles.blocoItensActive : styles.blocoItens}>
                    <ul>
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/about'>Sobre</Link></li>
                        <li><Link to='/school'>Escolas</Link></li>
                    </ul>

                    <Link to='/login'>Entrar</Link>
                </div>
            </nav>
        </header>
    )
}

export default Header
