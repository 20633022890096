// dependencies
import React from 'react'
// components
import Header from '../components/Header'
import Banner from '../components/Banner'
import Modal from './inscription/Modal'

export function Home() {
  const [modal, setModal] = React.useState(false);

  return (
    <section>
      <Header />
      <Banner setModal={setModal} />
      {modal && <Modal setModal={setModal} />}
    </section>
  )
}
